import * as React from 'react';

const ArrowIcon = ({ rotate }: { rotate: boolean }) => (
    <svg
        className={`h-4 w-4 ml-2 transform inline-hover ${
            rotate ? 'rotate-180' : 'rotate-0'
        } group-hover:rotate-180`}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg">
        <g
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round">
            <polyline stroke="currentColor" strokeWidth="2" points="5 9 12 16 19 9" />
        </g>
    </svg>
);
export default ArrowIcon;
